<template>
  <section>
    <div class="p-4 rounded-md shadow-md">
      <DataTable :value="dataTablaOv" v-model:selection="lineasSeleccionadas" dataKey="Id" responsiveLayout="scroll" >
        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column header="Articulo" headerClass="text-xs">
          <template #body="slotProps">
            <div class="text-xs">
              <div class="flex gap-2">
                <span class="flex gap-2 items-center">
                  {{ slotProps.data.Medicamento.ItemName }}
                </span>
              </div>
              <div class="flex gap-2">
                <p class="font-bold">Lote:</p><p class="font-bold text-gray-600">{{ slotProps.data.NumLote}}</p>
                <p class="font-bold">Precio:</p><p class="font-bold text-green-600">{{ $h.formatCurrency(slotProps.data.UnitPrice) }}</p>
              </div>
            </div>
          </template>
        </Column>
        <Column header="Cantidad entregada" headerClass="text-xs">
          <template #body="slotProps">
            <div class="text-xs">
              <span class="flex gap-2 items-center">
                {{ slotProps.data.Quantity }}
              </span>
            </div>
          </template>
        </Column>
        <Column header="Cantidad a devolver" headerClass="text-xs">
          <template #body="slotProps">
            <div class="text-xs">
              <span v-if="lineasSeleccionadas.some(a => a.Id === slotProps.data.Id)" class="flex gap-2 items-center">
                <InputNumber :max="parseInt(slotProps.data.Quantity)" :id="slotProps.data.Id" class="p-inputtext-sm" v-model="slotProps.data.devolucion" />
              </span>
              <span v-else class="flex gap-2 items-center">
                0
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
      <div class="w-full grid grid-cols-1 lg:grid-cols-3 mt-3">
        <div>
          <p class="text-gray-600 text-xs w-full">Comentarios:</p>
          <Textarea v-model="comentarios" class="w-full h-5/6" />
        </div>
        <div class="w-full flex lg:px-10 mt-3">
          <div class="w-6/12">
            <p class="text-xs text-gray-600">Creado por:</p>
            <p class="font-bold">{{ persona.full_name }}</p>
          </div>
          <div class="w-6/12">
            <p class="text-xs text-gray-600">Actualizado por:</p>
            <p class="font-bold">{{ persona.full_name }}</p>
          </div>
        </div>
        <div class="w-full grid grid-cols-1 gap-2">
          <Button @click="generarDevolucion" :disabled="lineasSeleccionadas.length && lineasSeleccionadas.some(a => a.devolucion > 0) ? false : true" label="Generar devolución" style="height: min-content;" class="p-button-danger mt-4" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { onMounted, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from '@/store'
  import { useOrdenesStore } from '../../../../stores/ordenes.store'
  import OrdenService from '../../../../services/orden.service'
  import Swal from 'sweetalert2'
  export default {
    name: 'devolucionOv',
    setup () {
      // Servicios
      const _OrdenService = ref(new OrdenService())
      // Referencias
      const show = ref(true)
      const lineasSeleccionadas = ref([])
      const route = useRoute()
      const router = useRouter()
      const dataTablaOv = ref([])
      const comentarios = ref('')

      // Stores
      const storePersona = useStore()
      const ordenesStore = useOrdenesStore()
      // Computadas
      const persona = storePersona.getters['auth/getPeople']
      // Métodos
      const generarDevolucion = () => {
        let lineas = ''
        if (lineasSeleccionadas.value.some(a => a.Mipres && a.Mipres.infoestdireccionamiento === 'REPORTADO')) {
            return Swal.fire(
              'Error al devolver',
              `No puede generar devolucion del medicamento ${lineasSeleccionadas.value.find(a => a.Mipres && a.Mipres.infoestdireccionamiento === 'REPORTADO').ItemCode}, ya que contiene mipres con numeros de direccionamiento en estado REPORTADO`,
              'error'
            )
          }
        lineasSeleccionadas.value.map(a => {
          lineas += a === lineasSeleccionadas.value[lineasSeleccionadas.value.length - 1] ? a.NumLote : (a.NumLote + ', ')
        })
        return Swal.fire({
          title: '¿Esta seguro?',
          html:
          'Esta seguro de generar la devolución de los lotes: ' +
          `<b>${lineas}</b>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Generar devolución',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            const object = {
              BaseId: route.params.id,
              Comments: comentarios.value,
              DocumentLines: lineasSeleccionadas.value.map(a => {
                return {
                  BaseId: a.id_ovdt,
                  LineNum: a.LineNum,
                  ItemCode: a.ItemCode,
                  CantidadEntregada: a.Quantity,
                  CantidadADevolver: a.devolucion,
                  WhsCode: a.WarehouseCode,
                  BatchNumber: a.NumLote,
                  StockMovements: a.StockMovements.filter(f => f.BatchNumber === a.NumLote)
                }
              })
            }
            _OrdenService.value.devolucion(object).then(({ data }) => {
              if (data) {
                Swal.fire(
                'Guardado!',
                'Medicamentos ingresados al stock, correctamente',
                'success'
              ).then(() => {
                lineasSeleccionadas.value = []
                router.push({ name: 'pharmasan.ventas.ov.ver.tabla', params: { id: route.params.id } })
                // encontrarOV()
              })
              }
            })
          }
        })
      }
      const encontrarOV = () => {
        dataTablaOv.value = []
        ordenesStore.resetData().then(() => {
          if (route.params.id) {
            ordenesStore.find(route.params.id).then((data) => {
              if (data.BodegaPrincipalUsuario) {
                if (data.BodegaPrincipalUsuario.idBodega !== data.U_PHR_Bodega) {
                  router.push({ name: 'pharmasan.ventas.ov.ver.tabla', params: { id: route.params.id } })
                }
              }
              if (data.sincronizacionSap && data.sincronizacionSap.Status === 2 && data.DocStatus === 1) return (router.push({ name: 'pharmasan.ventas.ov.ver.tabla', params: { id: route.params.id } }))
              for (const i of data.DocumentLines.filter(a => a.DelivrdQty)) {
                if (i.StockMovements.length) {
                  for (const j of i.StockMovements) {
                    const object = {
                      ...i,
                      Quantity: Math.abs(j.Quantity),
                      NumLote: j.BatchNumber,
                      Id: j.Id,
                      id_ovdt: i.Id,
                      mipres_reportado: !!(i.Mipres && i.Mipres.infoestdireccionamiento === 'REPORTADO')
                    }
                    dataTablaOv.value.push(object)
                  }
                }
              }
              dataTablaOv.value.map(a => {
                a.devolucion = 0
                a.comments = ''
              })
            })
          }
        })
      }

      onMounted(() => {
        encontrarOV()
      })
      return {
        lineasSeleccionadas,
        show,
        dataTablaOv,
        persona,
        generarDevolucion,
        comentarios
      }
    }
  }
</script>
